export default {
  h48: {
    fontSize: "48px",
    lineHeight: "1.5",
  },
  h40: {
    fontSize: "40px",
    lineHeight: "1.5",
  },
  h32: {
    fontSize: "32px",
    lineHeight: "1.5",
  },
};
