import styled, { css } from "styled-components";
import general from "../../../../styles/token/general";
import gradient from "../../../../styles/token/gradient";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0 71px;
`;

export const VideoSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const VideoWrapper = styled.div`
  position: relative;
`;

export const Video = styled.video`
  overflow: hidden;
  width: auto;
  height: 100%;
  object-fit: contain;
}`;

export const ResultText = styled.div`
  width: ${general.rectWidth};
  background: ${gradient.gradient02};
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);

  border-radius: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 28px;
  color: white;
  text-shadow: 0px 0px 35px rgba(0, 0, 0, 0.35);

  white-space: pre-line;
  line-height: 1.5;
  text-align: center;
  padding: 16px;

  ${(props: { warning?: boolean }) => {
    if (props.warning) {
      return css`
        color: #ff2c2c;
      `;
    }
  }};
`;
