import React from "react";
import * as S from "./Header.style";
import logoImageSrc from "../../assets/logo.svg";
import _ from "lodash";

interface HeaderProps {
  homeBtnDisabled?: boolean;
}
const Header = ({ homeBtnDisabled }: HeaderProps) => {
  return (
    <S.Header>
      <S.HomeButton
        disabled={homeBtnDisabled}
        onClick={() => {
          window.location.href = "/main/on-boarding";
        }}
      >
        <img src={logoImageSrc} alt="" />
      </S.HomeButton>
    </S.Header>
  );
};
export default Header;
