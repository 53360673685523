import React, { useState, useRef, useEffect } from "react";
import { StateHash } from "../../../../../../general/type/type";
import ProcessButton from "../../../../../../general/components/ProcessButton";
import * as S from "./FaceDetect.style";
import * as GeneralS from "../../Snapshot.style";
import http from "../../../../../../lib/http";
import { promiseTimeout } from "../../../../../../lib/time";
import { useNavigate, useLocation } from "react-router";
import { TOKEN_KEY } from "../../../../../../general/configs";

enum ShotStateHash {
  Shooting = "#shooting",
}
const COUNT_DOWN_SEC = 3;

//서버에서 문제될 경우 방지하기 위해 촬영된 이미지 사이즈 제한
const MAX_IMG_WIDTH = 2560;
const MAX_IMG_HEIGHT = 1440;

const FaceDetect = ({ setVideoId }: any) => {
  const { hash } = useLocation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    //initialization
    if (!hash) {
      setUserMessage("");
      setTimeLeft(COUNT_DOWN_SEC);
      const ctx = canvasRef.current!.getContext("2d");
      ctx!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    }
  }, [hash]);

  const [timeLeft, setTimeLeft] = useState(COUNT_DOWN_SEC);
  const [userMessage, setUserMessage] = useState("");
  const localStreamRef = useRef<MediaStream>();
  const [isUserMediaLoaded, setIsUserMediaLoaded] = useState(false);

  useEffect(() => {
    if (!videoRef.current) return;
    setIsUserMediaLoaded(false);
    if (navigator?.mediaDevices?.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          localStreamRef.current = stream;
          videoRef.current!.srcObject = stream;
        })
        .catch(function (error) {
          console.log("Something went wrong!");
          console.log(error);
          return;
        });
    }

    return () => {
      const mediaTrack = localStreamRef.current?.getTracks()?.[0];
      if (mediaTrack) mediaTrack.stop();
    };
  }, []);

  const navigate = useNavigate();

  return (
    <GeneralS.Wrapper>
      <GeneralS.VideoSection>
        <GeneralS.VideoWrapper>
          <GeneralS.Video
            ref={videoRef}
            autoPlay
            style={{
              transform: "rotateY(180deg)",
              maxWidth: MAX_IMG_WIDTH,
              maxHeight: MAX_IMG_HEIGHT,
            }}
            onLoadedData={(e) => {
              setIsUserMediaLoaded(true);
              //@ts-ignore
              canvasRef.current!.width = e.target!.offsetWidth;
              //@ts-ignore
              canvasRef.current!.height = e.target!.offsetHeight;
            }}
          />

          <S.Canvas ref={canvasRef} />
          <S.HtmlWrapper style={{ visibility: isUserMediaLoaded ? "visible" : "hidden" }}>
            {isUserMediaLoaded && !hash && (
              <>
                <S.ShootingGuide
                  style={{
                    top: 17,
                    left: 14,
                  }}
                >
                  얼굴을 원에 맞춰주세요
                </S.ShootingGuide>

                <S.ShootingGuide
                  style={{
                    bottom: 23,
                    right: 23,
                  }}
                >
                  촬영 버튼을 누르면{<br />} 3초 뒤에 찰칵!
                </S.ShootingGuide>
              </>
            )}

            {!!timeLeft && (
              <S.ShotGuideWrapper>
                <S.ShotGuideCircle />
                {hash === ShotStateHash.Shooting && <S.Time>{timeLeft}</S.Time>}
              </S.ShotGuideWrapper>
            )}

            {userMessage && (
              <GeneralS.ResultText warning={hash === StateHash.Rejected}>{userMessage}</GeneralS.ResultText>
            )}
          </S.HtmlWrapper>
        </GeneralS.VideoWrapper>
      </GeneralS.VideoSection>

      <S.ButtonSection style={{ visibility: isUserMediaLoaded ? "visible" : "hidden" }}>
        {!!timeLeft && (
          <S.ShotButton
            disabled={hash === ShotStateHash.Shooting}
            onClick={async () => {
              navigate(ShotStateHash.Shooting);

              const countTimer = setInterval(() => {
                setTimeLeft((timeLeft) => {
                  const reducedTimeLeft = timeLeft - 1;

                  if (reducedTimeLeft === 0) {
                    const context = canvasRef.current?.getContext("2d");

                    context!.save();
                    context!.translate(videoRef.current!.offsetWidth, 0);
                    context!.scale(-1, 1);

                    context!.drawImage(
                      videoRef.current!,
                      0,
                      0,
                      videoRef.current!.offsetWidth,
                      videoRef.current!.offsetHeight
                    );
                    context!.restore();

                    clearInterval(countTimer);
                  }

                  return reducedTimeLeft;
                });
              }, 1000);
            }}
          />
        )}

        {((hash === ShotStateHash.Shooting && !timeLeft) || (!!hash && hash !== ShotStateHash.Shooting)) && (
          <>
            <ProcessButton
              type="reset"
              disabled={hash === StateHash.Pending}
              onClick={() => {
                navigate("/main/snapshot/detect");
              }}
            />
            <ProcessButton
              disabled={hash === StateHash.Pending || hash === StateHash.Rejected}
              type="next"
              onClick={async () => {
                setUserMessage("얼굴을 확인 중입니다.");

                navigate(StateHash.Pending);

                const dataURL = canvasRef.current?.toDataURL("image/jpeg");
                const imageBytes = new Uint8Array(
                  atob(dataURL!.split(",")[1])
                    .split("")
                    .map(function (c) {
                      return c.charCodeAt(0);
                    })
                );

                const formData = new FormData();
                formData.append("file", new Blob([imageBytes], { type: "image/jpeg" }));

                try {
                  const { face_detect_result } = await http.post<{
                    face_detect_result: number;
                  }>("/face_detect", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                  });

                  setVideoId(face_detect_result);
                  navigate("/main/snapshot/result");
                } catch (e: any) {
                  console.log(e.response)
                  if (e.response.status === 401) {
                    // setUsermessage를 띄우고 2초 있다가 로그인 페이지로 이동
                    setUserMessage(e.response.data.detail);
                    await promiseTimeout(2000);

                    window.localStorage.removeItem(TOKEN_KEY);
                    window.location.href = "/login";
                  } else {
                    console.log(e.response.status);
                    setUserMessage(e.response.data.detail);
                    navigate(StateHash.Rejected);
  
                    (async () => {
                      await promiseTimeout(3000);
                      navigate("/main/snapshot/detect");
                    })();
                  }
                }
              }}
            />
          </>
        )}
      </S.ButtonSection>
    </GeneralS.Wrapper>
  );
};

export default FaceDetect;
