import React, { useState } from "react";
import { createContainer } from "unstated-next";

export const useHomeRevertContext = () => {
  const [homeBtnDisabled, setHomeBtnDisabled] = useState(false);

  return {
    homeBtnDisabled,
    setHomeBtnDisabled,
  };
};

export const HomeRevertContext = createContainer(useHomeRevertContext);
