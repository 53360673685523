import React from "react";
import { TOKEN_KEY } from "../../general/configs";
import { useAuth } from "../../general/hooks/useAuth";
import { useEffectOnce } from "../../general/hooks/useEffectOnce";

const Auth = () => {
  const { checkTokenValidation, logout } = useAuth();

  useEffectOnce(() => {
    const savedToken = window.localStorage.getItem(TOKEN_KEY) || "";

    if (savedToken) {
      checkTokenValidation(savedToken).catch((e) => {
        if (e.response.status === 401) logout();
      });
    } else if (!window.location.href.includes("login")) {
      window.location.href = "/login";
    }
  });

  return <></>;
};

export default Auth;
