import styled, { css } from "styled-components";
import gradient from "../../styles/token/gradient";

export const Button = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gradient.gradient02};
  box-shadow: 8px -20px 30px rgba(13, 137, 252, 0.4);
  cursor: pointer;
  font-weight: 800;
  font-size: 24px;
  color: white;
  gap: 12px;

  ${(props: { disabled?: boolean; isLast?: boolean }) => {
    if (props.disabled) {
      return css`
        background: rgba(119, 119, 119, 1);
        box-shadow: none;
        pointer-events: none;
      `;
    }

    if (props.isLast) {
      return css`
        width: auto;
        border-radius: 80px;
        padding: 0 32px;
      `;
    }
  }}
`;
