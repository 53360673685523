import styled, { css } from "styled-components";
import colors from "../../styles/token/colors";
import general from "../../styles/token/general";
import gradient from "../../styles/token/gradient";
import typography from "../../styles/token/typography";

export const Button = styled.button`
  width: 100%;
  max-width: ${general.rectWidth};
  height: 106px;
  border-radius: 24px;
  color: white;
  ${typography.h48}
  cursor: pointer;
  font-weight: 800;
  background: ${gradient.gradient02};
  flex-shrink: 0;

  ${(props: { disabled?: boolean }) => {
    if (props.disabled) {
      return css`
        background: #aaaaaa;
        pointer-events: none;
      `;
    }
  }}
`;
