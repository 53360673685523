import styled, { css } from "styled-components";
import colors from "../../styles/token/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled.form`
  width: 960px;
  height: 383px;
  border-radius: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 23px;
  font-weight: 600;
`;

const inputBox = css`
  width: 460px;
  height: 50px;
  border-radius: 16px;
  padding: 8px;
  font-size: 24px;
`;

export const Input = styled.input`
  ${inputBox};

  border: 2px solid ${colors.primary};
  text-align: center;

  &::placeholder {
    color: ${colors.primary};
  }
`;

export const Button = styled.button`
  ${inputBox};
  background: ${colors.primary};
  color: white;
  cursor: pointer;
`;
