import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { OptionContext } from "./contexts/optionContext";
import OnBoarding from "./components/OnBoarding/OnBoarding";
import Snapshot from "./components/Snapshot/Snapshot";
import NoActionDetector from "./NoActionDetector";

const _Main = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<Navigate to={"/main/on-boarding"} />} />
        <Route path="/on-boarding/*" element={<OnBoarding />} />
        <Route path="/snapshot/*" element={<Snapshot />} />
      </Routes>

      <NoActionDetector />
    </>
  );
};

const Main = () => {
  return (
    <OptionContext.Provider>
      <_Main />
    </OptionContext.Provider>
  );
};

export default Main;
