import { apiBaseUrl } from "./../general/configs";
import axios from "axios";
import _ from "lodash";
import { TOKEN_KEY } from "./../general/configs";
export const instance = axios.create({
  baseURL: apiBaseUrl,
});

instance.interceptors.request.use((config) => {
  config.params = {
    _json: JSON.stringify(config.params),
  };

  return config;
});

export const setHttpAuthToken = (token: string) => {
  instance.defaults.headers[TOKEN_KEY] = token;
};

interface CustomAxiosRequestConfig {
  //
}

const get = <T>(url: string, config?: CustomAxiosRequestConfig, responseKeys = "data") =>
  instance.get(url, config).then((res) => _.get(res, responseKeys) as T);

const post = <T>(url: string, data?: any, config?: CustomAxiosRequestConfig) =>
  instance.post(url, data, config).then((res) => res.data as T);

export default {
  get,
  post,
};
