import styled, { css } from "styled-components";

export const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const HomeButton = styled.button`
  margin: 30px;
  cursor: pointer;

  ${(props: { disabled?: boolean }) => {
    if (props.disabled) {
      return css`
        pointer-events: none;
      `;
    }
  }}
`;
