import React, { useRef } from "react";
import * as S from "./VideoCreateResult.style";
import * as GeneralS from "../../Snapshot.style";
import http from "../../../../../../lib/http";
import { useEffectOnce } from "../../../../../../general/hooks/useEffectOnce";
import GoButton from "../../../../../../general/components/GoButon";
import { OptionContext } from "../../../../contexts/optionContext";
import pendingVideoSrc from "../../../../../../assets/video/showroom_intro.webm";
import { useNavigate, useLocation } from "react-router";
import { StateHash } from "../../../../../../general/type/type";
import { promiseTimeout } from "../../../../../../lib/time";

const ERROR_MESSAGE = `서버 오류입니다. \n 담당자에게 문의해주세요.`;

export enum VideoCheckingStateHash {
  Checking = "#checking",
}

const VideoCreateResult = ({ videoId }: any) => {
  const createdVideoSrc = useRef<string>();
  const { hash } = useLocation();

  const { language, contentsType, gender: genderString } = OptionContext.useContainer();
  const videoRef = useRef<HTMLVideoElement>(null);

  const navigate = useNavigate();

  useEffectOnce(() => {
    (async () => {
      try {
        navigate(StateHash.Pending);
        await http.post(
          "/video_create",
          {
            content: contentsType,
            lang: language,
            video_id: videoId,
            gender: genderString?.[0], //남자, 여자에서 앞글자만
          },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        const video: BlobPart = await http.get(`/video_send/${videoId}`, { responseType: "blob" });
        const file = new File([video], "video", {
          type: "video/mp4",
        });

        const src = URL.createObjectURL(file);
        createdVideoSrc.current = src;

        navigate(StateHash.Fulfilled);
      } catch (e) {
        navigate(StateHash.Rejected);
        (async () => {
          await promiseTimeout(3000);
          navigate("/main/on-boarding");
        })();
      }
    })();
  });

  return (
    <GeneralS.Wrapper style={{ padding: "164px 0" }}>
      <GeneralS.VideoSection>
        <GeneralS.VideoWrapper
          style={{
            marginBottom: 40,
          }}
        >
          <GeneralS.Video autoPlay ref={videoRef} loop src={pendingVideoSrc} />

          <S.HtmlWrapper>
            {hash === StateHash.Rejected && <GeneralS.ResultText warning>{ERROR_MESSAGE}</GeneralS.ResultText>}
          </S.HtmlWrapper>
        </GeneralS.VideoWrapper>
      </GeneralS.VideoSection>
      <GoButton
        string={hash === StateHash.Rejected || hash === VideoCheckingStateHash.Checking ? "처음으로" : "영상 확인"}
        disabled={hash === StateHash.Pending}
        onClick={() => {
          if (hash === StateHash.Fulfilled) {
            videoRef.current!.src = createdVideoSrc.current as string;
            videoRef.current!.load();
            navigate(VideoCheckingStateHash.Checking);
          } else {
            navigate("/main/on-boarding");
          }
        }}
      />
    </GeneralS.Wrapper>
  );
};

export default VideoCreateResult;
