import styled, { css } from "styled-components";
import gradient from "../../../../styles/token/gradient";
import typography from "../../../../styles/token/typography";
import general from "../../../../styles/token/general";
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  width: ${general.rectWidth};
  height: 106px;
  border-radius: 24px;
  color: white;
  ${typography.h48}
  cursor: pointer;
  font-weight: 800;
`;

export const StartButton = styled(Button)`
  background: ${gradient.gradient01};
`;

export const PolicySection = styled.div`
  width: ${general.rectWidth};
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding: 96px 0;
`;

export const PolicyContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 24px;
`;

export const PolicyContentsHeader = styled.div`
  display: flex;
  height: 90px;
  background: ${gradient.gradient02};
  color: white;
  font-size: 28px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 64px;
  flex-shrink: 0;
`;

export const PolicyContents = styled.div`
  padding: 24px 64px;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-y: hidden;
`;

export const PolicyDescription = styled.p`
  margin-top: 30px;
`;

export const ParagraphSection = styled.div`
  margin-bottom: 20px;
`;

export const ParagraphTitle = styled.p`
  font-size: 24px;
  height: 36px;
  margin-bottom: 10px;
`;

export const PolicyContentsInner = styled.div`
  height: 100%;
  overflow-y: auto;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  color: rgba(119, 119, 119, 1);

  // Scroll
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: #fafafa;
  }
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
`;

export const OptionSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0px 140px 0 130px;
`;

export const OptionContentsWrapper = styled.div`
  width: ${general.rectWidth};
  margin-left: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AvatarWrapper = styled.div`
  width: 540px;
  height: 100%;
`;
export const AvatarVideo = styled.video`
  width: 100%;
  height: 100%;
  margin-top: 120px;
`;

export const QuestionBox = styled(PolicyContentsHeader)`
  width: 100%;
  height: 112px;
  border-radius: 24px;
  margin-bottom: 40px;
  flex-shrink: 0;
`;

export const OptionListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  max-height: 480px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const OptionItemBox = styled.div`
  cursor: pointer;
  flex: 1 0 0;

  min-width: 30%;
  padding: 0 40px;

  height: 80px;
  min-height: 80px;

  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.36) 0%,
    rgba(255, 255, 255, 0.12) 99.99%,
    rgba(255, 255, 255, 0.072) 100%
  );

  position: relative;

  //gradient background 영향 안받으려고 border를 before에 따로 지정함
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid white;
    border-radius: inherit;
    ${(props: { selected?: boolean }) => {
      if (props.selected) {
        return css`
          border: 3px solid transparent;
        `;
      }
    }}
  }

  ${(props: { selected?: boolean }) => {
    if (props.selected) {
      return css`
        pointer-events: none;
        background: ${gradient.gradient01};
      `;
    }
  }}
`;

export const ProgressButtonSection = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 40px;
`;
