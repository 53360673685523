export const apiBaseUrl = "https://xr.chani.pro/proof";

export const TOKEN_KEY = "token";

export const PRIVACY_POLICY_CONTENTS = {
  title: "개인 정보 수집 및 이용",
  paragraphs: [
    {
      title: "● 고지사항",
      items: [
        "회사는 체험 시 취득한 이용자의 개인정보를 본 체험의 일환으로서 합성 목적 외 일절 사용하지 않습니다.",
        "회사는 이용자에게 제공하기 위한 목적 외에 결과 영상을 일절 사용하지 않습니다.",
        "회사는 목적 달성을 위해 최소한의 개인정보만을 수집하고 처리합니다.",
        "회사는 운영자 외 제3자가 개인정보 데이터 보관 장소에 접근하여 개인정보를 무단으로 열람 및 유출하지 못하도록 접근을 제한합니다.",
      ],
      isOrdered: true,
    },
    {
      title: "● 개인정보 처리 목적",
      items: ["이용자의 얼굴 사진을 촬영 후 합성하여 본 체험 콘텐츠로서 합성 결과 영상을 제공하기 위함"],
    },
    {
      title: "● 개인정보 항목",
      items: ["이용자의 얼굴 사진", "이용자의 얼굴 사진 기반으로 합성한 결과 영상"],
    },
    {
      title: "● 개인정보 처리 및 보유 기간",
      items: [
        "이용자의 얼굴 사진을 촬영한 뒤 이를 위탁 서버에 저장",
        "이용자의 얼굴 사진 기반으로 합성한 결과 영상을 위탁 서버에서 생성 및 저장",
        "사진과 영상 데이터는 저장 당일부터 익일까지 보유",
      ],
    },
    {
      title: "● 개인정보 처리의 위탁",
      items: [
        "수탁업체: Google Cloud Platform",
        "위탁하는 업무 내용: 서비스 제공을 위한 시스템 운영",
        "위탁 시스템에 대한 개인정보의 보유 및 이용기간: 서비스 이용 익일 또는 위탁 계약 종료시까지 보유",
        "수탁업체에서 제공하는 시스템의 지역 설정 선택은 국내로 한정하여 구성",
      ],
    },
    {
      title: "● 개인정보 파기 절차 및 방법",
      items: [
        "매일 특정 시간에 전날 수집한 이용자의 얼굴 사진 및 결과 영상 데이터를 복구 불가능하도록 일괄적으로 삭제",
      ],
    },
    {
      title: "● 동의거부권 및 거부에 따른 불이익",
      items: ["이용자는 이에 동의하지 않을 권리가 있으며 동의하지 않을 시 체험을 진행할 수 없습니다."],
    },
  ],
  description: "상기 내용에 동의하고 콘텐츠 체험 진행을 원하신다면 아래 동의하기 버튼을 눌러 주세요.",
};
