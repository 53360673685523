import styled, { css } from "styled-components";
import colors from "../../../../../../styles/token/colors";

export const Canvas = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const ButtonSection = styled.section`
  margin-top: 55px;
  display: flex;
  justify-content: center;
  gap: 200px;
`;

export const ShootingGuide = styled.div`
  position: absolute;
  background-color: rgba(0, 17, 75, 0.7);
  border-radius: 45px 45px 0px 45px;
  font-weight: 700;
  font-size: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 25px;
  white-space: pre-line;
  text-align: center;
  line-height: 36px;
`;

export const ShotButton = styled.button`
  width: 75px;
  height: 75px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  color: white;
  border: 1px solid currentColor;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 3px;
    background-color: currentColor;
    border-radius: inherit;
  }

  ${(props: { disabled?: boolean }) => {
    if (props.disabled) {
      return css`
        color: #aaaaaa;
        pointer-events: none;
      `;
    }
  }}
`;

export const HtmlWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
`;

export const ShotGuideWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShotGuideCircle = styled.div`
  position: absolute;
  width: 360px;
  height: 420px;
  border: 10px solid ${colors.back};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Time = styled.h1`
  font-weight: 900;
  font-size: 250px;
  color: ${colors.back};
  text-shadow: 0px 0px 35px rgba(0, 0, 0, 0.35);
`;
