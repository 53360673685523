export default {
  primary: "#32c6f0",
  back: "#00114b",
  b0: "#000",
  b3: "#333",
  b5: "#555",
  b7: "#777",
  ba: "#aaaaa",
  bea: "#eaeaea",
  be: "#eeeee",
  bf0: "#f0f0f0",
  bf5: "#f5f5f5",
  bfa: "#fafafa",
};
