import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import colors from "./styles/token/colors";
import Login from "./components/Login/Login";
import Auth from "./components/Auth/Auth";
import { TOKEN_KEY } from "./general/configs";
import Main from "./components/Main/Main";
import Header from "./components/Header/Header";
import { HomeRevertContext } from "./general/contexts/homeRevertContext";

const App = () => {
  const { homeBtnDisabled } = HomeRevertContext.useContainer();

  return (
    <div
      className="App"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: colors.back,
      }}
    >
      <Header homeBtnDisabled={homeBtnDisabled} />
      <Auth />
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={<Navigate to={window.localStorage.getItem(TOKEN_KEY) ? "/main/on-boarding" : "/login"} replace />}
          />
          <Route path="/login/*" element={<Login />} />
          <Route path="/main/*" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const ConnectedApp = () => (
  <HomeRevertContext.Provider>
    <App />;
  </HomeRevertContext.Provider>
);

export default ConnectedApp;
