import * as S from "./Login.style";
import React, { useState, useEffect } from "react";
import http from "../../lib/http";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../general/hooks/useAuth";
import { HomeRevertContext } from "../../general/contexts/homeRevertContext";

const Login = () => {
  const navigate = useNavigate();
  const { setHomeBtnDisabled } = HomeRevertContext.useContainer();

  useEffect(() => {
    setHomeBtnDisabled(true);
  }, []);

  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const { checkTokenValidation } = useAuth();

  return (
    <S.Wrapper>
      <S.LoginForm
        onSubmit={async (e) => {
          e.preventDefault();

          try {
            const { token } = await http.post<{
              status: string;
              token: string;
            }>(
              "/token",
              { username: loginId, password: password },
              { headers: { "Content-Type": "multipart/form-data" } }
            );
            checkTokenValidation(token);
            navigate("/main/on-boarding");
          } catch {
            window.alert("아이디 혹은 패스워드를 다시 확인해주세요");
          }
        }}
      >
        <S.Input
          required
          placeholder="ID"
          type="text"
          value={loginId}
          onChange={(e) => setLoginId((e.target as HTMLInputElement).value)}
        ></S.Input>
        <S.Input
          required
          placeholder="PASSWORD"
          type="password"
          value={password}
          onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
        ></S.Input>
        <S.Button type="submit">LOGIN</S.Button>
      </S.LoginForm>
    </S.Wrapper>
  );
};

export default Login;
