import { redirect } from "react-router-dom";
import { TOKEN_KEY } from "../configs";
import { setHttpAuthToken } from "../../lib/http";
import React, { useCallback } from "react";
import http from "../../lib/http";
export const useAuth = () => {
  const logout = useCallback(() => {
    window.localStorage.removeItem(TOKEN_KEY);
    window.location.href = "/login";
  }, []);

  const checkTokenValidation = useCallback(async (token: string) => {
    window.localStorage.setItem(TOKEN_KEY, token);
    setHttpAuthToken(token);

    //유저가 가진 토큰 유효성 검사 후 유효한 토큰이면 대체됨
    const { token: replacedToken } = await http.get<{ token: string }>("/token", {
      headers: {
        token,
      },
    });

    window.localStorage.setItem(TOKEN_KEY, replacedToken);
    setHttpAuthToken(replacedToken);
  }, []);

  return { logout, checkTokenValidation };
};
