import React, { useState } from "react";

import { Routes, Navigate, Route } from "react-router-dom";
import VideoCreateResult from "./component/VideoCreateResult/VideoCreateResult";
import FaceDetect from "./component/FaceDetect/FaceDetect";

const Snapshot = () => {
  const [videoId, setVideoId] = useState();

  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/main/snapshot/detect"} />} />
      <Route path="/detect" element={<FaceDetect setVideoId={setVideoId} />} />
      <Route path="/result" element={<VideoCreateResult videoId={videoId} />} />
    </Routes>
  );
};

export default Snapshot;
