import React, { useState, useMemo, useRef, useCallback, useEffect } from "react";
import * as S from "./OnBoarding.style";
import { OptionContext } from "../../contexts/optionContext";
import _ from "lodash";
import { useNavigate } from "react-router";
import { PRIVACY_POLICY_CONTENTS } from "../../../../general/configs";

import GoButton from "../../../../general/components/GoButon";

import genderAvatarSrc from "../../../../assets/video/avatar_gender.webm";
import langAvatarSrc from "../../../../assets/video/avatar_lang.webm";
import typeAvatarSrc from "../../../../assets/video/avatar_type.webm";
import ProcessButton from "../../../../general/components/ProcessButton";

enum OnBoardingProcess {
  Start = "start",
  Policy = "policy",
  Option = "option",
}


const OnBoarding = () => {
  const [currentProcess, setCurrentProcess] = useState(OnBoardingProcess.Start);

  return (
    <S.Wrapper>
      {currentProcess === OnBoardingProcess.Start && (
        <S.StartButton onClick={() => setCurrentProcess(OnBoardingProcess.Policy)}>시작하기</S.StartButton>
      )}
      {currentProcess === OnBoardingProcess.Policy && (
        <S.PolicySection>
          <S.PolicyContentsWrapper>
            <S.PolicyContentsHeader>{PRIVACY_POLICY_CONTENTS.title}</S.PolicyContentsHeader>
            <S.PolicyContents>
              <S.PolicyContentsInner>
                {PRIVACY_POLICY_CONTENTS.paragraphs.map((prgrph) => (
                  <S.ParagraphSection key={prgrph.title}>
                    <S.ParagraphTitle>{prgrph.title}</S.ParagraphTitle>
                    <ul style={{ listStyle: prgrph.isOrdered ? "decimal" : "disc", paddingLeft: 40 }}>
                      {prgrph.items.map((sentence) => (
                        <li key={sentence}>{sentence}</li>
                      ))}
                    </ul>
                  </S.ParagraphSection>
                ))}
                <S.PolicyDescription>{PRIVACY_POLICY_CONTENTS.description}</S.PolicyDescription>
              </S.PolicyContentsInner>
            </S.PolicyContents>
          </S.PolicyContentsWrapper>
          <GoButton
            string="동의"
            onClick={() => {
              setCurrentProcess(OnBoardingProcess.Option);
            }}
          />
          <GoButton
            string="미동의"
            style={{ background: "#aaaaaa" }}
            onClick={() => {
              setCurrentProcess(OnBoardingProcess.Start);
            }}
          />
        </S.PolicySection>
      )}

      {currentProcess === OnBoardingProcess.Option && <OptionSelectProcess />}
    </S.Wrapper>
  );
};

export default OnBoarding;

enum OptionProcess {
  Gender = "gender",
  Language = "language",
  Contents = "contents",
}

const optionProcesses = [OptionProcess.Gender, OptionProcess.Language, OptionProcess.Contents];

const OptionSelectProcess = () => {
  const { contentsList, languageList, gender, setGender, language, setLanguage, contentsType, setContentsType } =
    OptionContext.useContainer();
  const navigate = useNavigate();

  useEffect(() => {
    setContentsType(contentsList?.[language as string][0]);
  }, [language])
  
  const avatarVideoRef = useRef<HTMLVideoElement>(null);
  const [currentProcessIndex, _setCurrentProcessIndex] = useState(0);

  const setCurrentProcessIndex = useCallback((index: number) => {
    _setCurrentProcessIndex(index);
    avatarVideoRef.current!.load();
  }, []);
  const currentOptionProcess = useMemo(() => optionProcesses[currentProcessIndex], [currentProcessIndex]);

  const optionDataMap = useMemo(() => {

    return {
      [OptionProcess.Gender]: {
        questionText: "성별을 선택해주세요",
        state: gender,
        optionList: ["남자", "여자"],
        videoSrc: genderAvatarSrc,
        onClickHandle: (gender: string) => {
          setGender(gender);
        },
      },
      [OptionProcess.Language]: {
        questionText: "어떤 언어로 영상을 만들까요?",
        state: language,
        optionList: languageList,
        videoSrc: langAvatarSrc,
        onClickHandle: (language: string) => {
          setLanguage(language);
        },
      },
      [OptionProcess.Contents]: {
        questionText: "어떤 영상을 만들고 싶으세요?",
        state: contentsType,
        optionList: contentsList?.[language as string],
        videoSrc: typeAvatarSrc,
        onClickHandle: (type: string) => {
          setContentsType(type);
        },
      },
    };
  }, [gender, language, contentsType]);

  return (
    <S.OptionSection>
      <S.AvatarWrapper>
        <S.AvatarVideo autoPlay ref={avatarVideoRef} loop src={optionDataMap[currentOptionProcess].videoSrc} />
      </S.AvatarWrapper>
      <S.OptionContentsWrapper>
        <S.QuestionBox>{optionDataMap[currentOptionProcess].questionText}</S.QuestionBox>
        <S.OptionListWrapper
          style={{
            flexDirection: currentOptionProcess === OptionProcess.Contents ? "column" : "row",
          }}
        >
          {_.map(optionDataMap?.[currentOptionProcess].optionList, (optionText) => (
            <S.OptionItemBox
              key={optionText}
              onClick={() => {
                optionDataMap?.[currentOptionProcess].onClickHandle(optionText);
              }}
              style={{
                justifyContent: currentOptionProcess === OptionProcess.Contents ? "flex-start" : "center",
              }}
              selected={optionDataMap?.[currentOptionProcess].state === optionText}
            >
              {optionText}
            </S.OptionItemBox>
          ))}
        </S.OptionListWrapper>

        <S.ProgressButtonSection>
          <ProcessButton
            type="prev"
            disabled={currentProcessIndex === 0}
            onClick={() => {
              setCurrentProcessIndex(Math.max(currentProcessIndex - 1, 0));
            }}
          />
          <ProcessButton
            type="next"
            style={
              currentProcessIndex === optionProcesses.length - 1
                ? { width: "auto", borderRadius: 80, padding: "0 32px" }
                : {}
            }
            disabled={!optionDataMap?.[currentOptionProcess].state}
            onClick={() => {
              if (currentProcessIndex === optionProcesses.length - 1) navigate("/main/snapshot");

              setCurrentProcessIndex(Math.min(currentProcessIndex + 1, optionProcesses.length - 1));
            }}
          >
            {currentProcessIndex === optionProcesses.length - 1 && <p>NEXT STEP</p>}
          </ProcessButton>
        </S.ProgressButtonSection>
      </S.OptionContentsWrapper>
    </S.OptionSection>
  );
};
