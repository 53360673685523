import React, { useEffect, useRef } from "react";
import { StateHash } from "../../general/type/type";
import { useLocation } from "react-router-dom";
import { HomeRevertContext } from "../../general/contexts/homeRevertContext";

const NoActionDetector = () => {
  const { pathname, hash } = useLocation();
  const { setHomeBtnDisabled } = HomeRevertContext.useContainer();

  const initTimerRef = useRef<number>();

  useEffect(() => {
    setHomeBtnDisabled(hash === StateHash.Pending || hash === StateHash.Rejected);
    if (location.pathname.includes("/snapshot/result") && location.hash === StateHash.Pending) {
      if (initTimerRef.current) window.clearTimeout(initTimerRef.current);
    }
  }, [pathname, hash]);

  const resetTimer = () => {
    const location = window.location;
    if (initTimerRef.current) window.clearTimeout(initTimerRef.current);
    if (location.pathname.includes("/snapshot/result") && location.hash === StateHash.Pending) return;

    initTimerRef.current = window.setTimeout(() => {
      location.href = "/main/on-boarding";
    }, 1000 * 60);
  };

  useEffect(() => {
    window.addEventListener("touchstart", resetTimer);

    return () => {
      window.removeEventListener("touchstart", resetTimer);
    };
  }, []);

  return <></>;
};

export default NoActionDetector;
