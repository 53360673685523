import React from "react";
import * as S from "./GoButton.style";

interface GoButtonProps {
  string: string;
  disabled?: boolean;

  [x: string]: any;
}

const GoButton = ({ string, disabled, ...props }: GoButtonProps) => {
  return (
    <S.Button {...props} disabled={disabled}>
      {string}
    </S.Button>
  );
};

export default GoButton;
