import React, { useMemo, FC } from "react";
import * as S from "./ProcessButton.style";
import nextIconSrc from "../../assets/next.svg";
import nextDisabledIconSrc from "../../assets/next-disabled.svg";
import resetIconSrc from "../../assets/reset.svg";
import resetDisabledIconSrc from "../../assets/reset-disabled.svg";
import prevIconSrc from "../../assets/prev.svg";
import prevDisabledIconSrc from "../../assets/prev-disabled.svg";

interface ProcessButtonProps {
  type: "reset" | "next" | "prev";
  disabled?: boolean;

  [x: string]: any;
}
const ProcessButton: FC<ProcessButtonProps> = ({ type, disabled, children, ...props }) => {
  const iconSrc = useMemo(() => {
    if (type === "reset") {
      if (disabled) return resetDisabledIconSrc;
      return resetIconSrc;
    } else if (type === "next") {
      if (disabled) return nextDisabledIconSrc;
      return nextIconSrc;
    } else if (type === "prev") {
      if (disabled) return prevDisabledIconSrc;
      return prevIconSrc;
    }
  }, [type, disabled]);

  return (
    <S.Button disabled={disabled} {...props}>
      {children}
      <img src={iconSrc} alt="" />
    </S.Button>
  );
};

export default ProcessButton;
