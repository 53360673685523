import { forEach } from "lodash";
import React, { useState, useRef } from "react";
import { ObjectType } from "typescript";
import { createContainer } from "unstated-next";
import { useEffectOnce } from "../../../general/hooks/useEffectOnce";
import http from "../../../lib/http";

export const useOptionContext = () => {
  const [gender, setGender] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [contentsType, setContentsType] = useState<string>();

  interface test_obj {
    [key: string]: string;
  }

  const contentsList = useRef<test_obj>();
  const languageList = useRef<string[]>();

  useEffectOnce(() => {
    (async () => {
      // const { lang_arr } = await http.get<{ lang_arr: string[] }>("/language_option");
      
      interface MyObj {
        [key: string]: string;
      }
      
      const response : MyObj = await http.get("/lang_cont_option");
      const lang_arr = Object.keys(response);
      const content_arr: MyObj = {};
      for (const lang of lang_arr) {
        content_arr[lang] = response[lang];
      }
      
      contentsList.current = content_arr;
      setContentsType(content_arr[lang_arr?.[0]][0]);
      languageList.current = lang_arr;
      setLanguage(lang_arr?.[0]);
    })();
  });

  return {
    contentsList: contentsList.current,
    languageList: languageList.current,
    gender,
    setGender,
    language,
    setLanguage,
    contentsType,
    setContentsType,
  };
};

export const OptionContext = createContainer(useOptionContext);
